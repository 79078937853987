import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { Alert, Layout } from "antd";
import React from "react";
import styled from "styled-components";

const StyledAlert = styled(Alert)`
    text-align: center;
    font-size: 16px;
    background-color: rgba(255, 204, 199, 0.4) !important;
    border: 1px solid #ffccc7 !important;
    width: 100%;
    border-radius: 0;
    svg {
        color: red;
    }
`;

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
`;
export const ServiceDown = () => {
    return (
        <Layout
            style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
            }}
        >
            <Layout.Content style={{ flex: 1 }}>
                <QuantacoLoader size={150} />
                <StyledContainer>
                    <StyledAlert
                        type="warning"
                        message={process.env.REACT_APP_SERVICE_DOWN}
                    ></StyledAlert>
                </StyledContainer>
            </Layout.Content>
            <Layout.Footer style={{ textAlign: "center" }}>
                Quantaco ©{new Date().getFullYear()} All rights reserved
            </Layout.Footer>
        </Layout>
    );
};
