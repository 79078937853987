import React from "react";
// import "@react-awesome-query-builder/antd/lib/css/styles.css";
import { MappingRuleList } from "./MappingRuleList";
import { MappingConfiguratorLayout } from "./MappingConfiguratorLayout";
import BaseDesktopPage from "Pages/BaseDesktopPage";

export const MappingConfiguratorPage: React.FC = () => {
    return (
        <>
            <BaseDesktopPage page="mapping">
                <MappingConfiguratorLayout>
                    <MappingRuleList />
                </MappingConfiguratorLayout>
            </BaseDesktopPage>
        </>
    );
};
