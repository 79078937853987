import React, { ReactElement, useMemo } from "react";
import { Layout } from "antd";
import { TabBar } from "antd-mobile";
import HomeDashboardMobile from "Components/HomeDashboardMobile";
import TopNavigation from "Components/TopNavigation";
import styled from "styled-components";
import { AppOutline, UnorderedListOutline } from "antd-mobile-icons";
import { MobileSettingsPage } from "../MobileSettingsScreen/MobileSettingsScreen";
import { ForecastMobile } from "Pages/Forecast/Components/Carousel/MobileCarousel";
import NewNavBar from "Components/NewNavBar";
import { AreaChartOutlined } from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import { useBegoneWidget } from "Utils/widget";

const GreyBackgroundDiv = styled.div`
    background: #f0f2f5;
    min-height: 100vh;
`;

const MarginContentMobile = styled(Layout.Content)`
    margin: 10px;
`;

const BottomBanner = styled.div`
    bottom: 0;
    position: fixed;
    height: 55px;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ddd;
    z-index: 150;
`;

type ViewKeys = "home" | "settings" | "forecasting";

export const MobileHomeScreen = () => {
    const [activeTab, setActiveTab] = useLocalStorageState<
        "home" | "settings" | "forecasting"
    >("switch_mobile_state", { defaultValue: "home" });

    useBegoneWidget();

    const tabs: Array<{ key: ViewKeys; title: string; icon: ReactElement }> = [
        {
            key: "home",
            title: "Home",
            icon: <AppOutline />,
        },
        // {
        //     key: "forecasting",
        //     title: "Forecast",
        //     icon: <AreaChartOutlined />,
        // },
        {
            key: "settings",
            title: "Settings",
            icon: <UnorderedListOutline />,
        },
    ];

    const CurrentTabSwitch = useMemo(() => {
        switch (activeTab) {
            case "home":
                return <HomeDashboardMobile />;

            case "settings":
                return <MobileSettingsPage />;

            case "forecasting":
                return <ForecastMobile />;
        }
    }, [activeTab]);

    return (
        <GreyBackgroundDiv>
            <NewNavBar />
            {/* <TopNavigation mode={"mobile"} /> */}
            <MarginContentMobile>{CurrentTabSwitch}</MarginContentMobile>

            <BottomBanner>
                <TabBar
                    activeKey={activeTab}
                    onChange={(value) => {
                        setActiveTab(value as ViewKeys);
                    }}
                >
                    {tabs.map((item) => (
                        <TabBar.Item
                            key={item.key}
                            icon={item.icon}
                            title={item.title}
                        />
                    ))}
                </TabBar>
            </BottomBanner>
        </GreyBackgroundDiv>
    );
};
