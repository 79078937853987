import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { RoundedCard } from "../HomeDashboardDesktop";
import { Col, PageHeader, Row, Switch, Tabs } from "antd";
import DriversCard from "../DriversCard";
import { useHistory } from "react-router-dom";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { Context } from "../../State/store";
import ForecastingChartsDataManager from "../ForecastingChartsDataManager";
import { QUANTACO_CHART_DARK_ORANGE } from "../../Utils/constants";
import WeekPeriodSelectorManager from "./WeekPeriodSelectorManager";
import { RoutePaths } from "../Router/Routes";
import InfoTooltip from "../InfoTooltip";
import { BASELINE_CARD_TOOLTIP_TEXT, DRIVERS_CARD_TOOLTIP_TEXT } from "./constants";
import {
    ToggleApplyDriverForecasts,
    ToggleCreateForecastAutogen,
    ToggleCreateForecastBaseline,
} from "State/actions";
import styled from "styled-components";

const StyledSwitch = styled(Switch)`
    && {
        background-color: ${QUANTACO_CHART_DARK_ORANGE};
    }
`;

export default function ForecastCreation(): ReactElement {
    const history = useHistory();
    const [{ groupData }, dispatch] = React.useContext(Context);
    const { forecast } = useCurrentForecast();
    const [autogenChecked, setAutogenChecked] = useState(true);
    const isAutogenSupport = useMemo(
        () => groupData?.group.is_pilot_forecast,
        [groupData]
    );
    const initialKey = useMemo(
        () => (isAutogenSupport ? "2" : "1"),
        [isAutogenSupport]
    );
    const [activeKey, setActiveKey] = useState(initialKey);
    //Apply autogen data on initial load
    useEffect(() => {
        if (isAutogenSupport) {
            const action: ToggleCreateForecastAutogen = {
                type: "TOGGLE_CREATE_FORECAST_AUTOGEN",
            };
            dispatch(action);
        } else {
            if (forecast.baseline.length > 0) {
                const action:
                    | ToggleApplyDriverForecasts
                    | ToggleCreateForecastBaseline = {
                    type:
                        forecast.drivers.length > 0
                            ? "TOGGLE_APPLY_DRIVER_FORECASTS"
                            : "TOGGLE_CREATE_FORECAST_BASELINE",
                };
                dispatch(action);
            }
        }
    }, []);

    return (
        <>
            <PageHeader
                onBack={() => history.push(RoutePaths.FORECASTING_LANDING)}
                title={forecast?.forecastLabel}
            />
            <Row gutter={[8, 20]}>
                <Col span={24}>
                    <RoundedCard>
                        {isAutogenSupport ? (
                            <StyledSwitch
                                checkedChildren="Autogen"
                                unCheckedChildren="Baseline"
                                defaultChecked
                                checked={autogenChecked}
                                onChange={(checked) => {
                                    if (checked) {
                                        dispatch({
                                            type: "TOGGLE_CREATE_FORECAST_AUTOGEN",
                                        });
                                        setActiveKey("2");
                                    } else {
                                        const action: ToggleCreateForecastBaseline =
                                            {
                                                type: "TOGGLE_CREATE_FORECAST_BASELINE",
                                            };
                                        dispatch(action);
                                        setActiveKey("1");
                                    }
                                    setAutogenChecked(checked);
                                }}
                            />
                        ) : null}
                        <Tabs
                            activeKey={activeKey}
                            onChange={(key) => setActiveKey(key)}
                            tabBarExtraContent=""
                        >
                            {isAutogenSupport && autogenChecked ? null : (
                                <Tabs.TabPane
                                    tab={
                                        <InfoTooltip
                                            tooltipText={BASELINE_CARD_TOOLTIP_TEXT}
                                        >
                                            Create Sales Forecast
                                        </InfoTooltip>
                                    }
                                    key="1"
                                >
                                    {isAutogenSupport ? (
                                        <WeekPeriodSelectorManager
                                            setChecked={setAutogenChecked}
                                        />
                                    ) : (
                                        <WeekPeriodSelectorManager />
                                    )}
                                </Tabs.TabPane>
                            )}

                            <Tabs.TabPane
                                tab={
                                    <InfoTooltip
                                        tooltipText={DRIVERS_CARD_TOOLTIP_TEXT}
                                    >
                                        Adjust Sales Forecast
                                    </InfoTooltip>
                                }
                                key="2"
                                disabled={forecast.baseline.length === 0}
                            >
                                {isAutogenSupport ? (
                                    <DriversCard autogenChecked={autogenChecked} />
                                ) : (
                                    <DriversCard />
                                )}
                            </Tabs.TabPane>
                        </Tabs>
                    </RoundedCard>
                </Col>
                <ForecastingChartsDataManager />
                {/* {(ENV === "dev" || process.env.NODE_ENV === "development") && (
                    <Col span={24}>
                        <DevelopmentDebugPanel />
                    </Col>
                )} */}
            </Row>
        </>
    );
}
