import { MappingRule, MappingRuleResult } from "Api/mappingConfigurator";
import { CubeContext } from "@cubejs-client/react";
import {
    useListIntegrationSources,
    useListMappingRules,
    useListSegmentsByIntegrationSource,
} from "Hooks/mappingConfigurator";
import { chain, Dictionary } from "lodash";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useDaysFilter, useHoursFilter, useSegmentsFilter } from "Store/filterStore";
import { KeyValue } from "types";
import { CubeQueryBuilder } from "Utils/cubejs-utils";
import {
    calculateNumberOfDaysAndWeeksSelectedInRange,
    DateRange,
} from "Utils/date-utils";
import { formatNumberToFixed } from "Utils/number-utils";
import {
    SaleAndStaffHoursBySegment,
    TotalSalesAndStaffHours,
} from "../dashboard-model";
import {
    CubeName,
    getDefaultTotalSalesAndStaffHours,
    QueryKey,
} from "../dashboard-utils";
import { buildCubeFilters } from "Utils/mapping-configurator-utils";

interface GetSalesAndStaffHours {
    range: DateRange<string>;
    enabled?: boolean;
}

const addVenueIdsIntoParsingResult = (params: {
    result: MappingRuleResult[];
    parentVenueIdByAreaId: Dictionary<Array<string>>;
}) => {
    const { result, parentVenueIdByAreaId } = params;

    return result.map(({ areaIds, ...others }) => {
        return {
            ...others,
            areaIds,
            venueIds: areaIds?.map((areaId) => parentVenueIdByAreaId[areaId]).flat(),
        };
    });
};

const buildSegmentTree = ({
    areaIds,
    venueIds,
    classIds,
    serviceIds,
    parentVenueIdByAreaIds,
}: {
    areaIds?: string[];
    venueIds?: string[];
    classIds?: string[];
    serviceIds: number[];
    parentVenueIdByAreaIds: Dictionary<Array<string>>;
}) => {
    const result: string[] = [];

    if (classIds) {
        serviceIds.forEach((serviceId) => {
            classIds.forEach((classId) => {
                result.push(`${serviceId}_${classId}`);
            });
        });

        return result;
    }
    serviceIds.forEach((serviceId) => {
        if (venueIds) {
            venueIds.forEach((venueId) => {
                if (!areaIds) {
                    result.push(`${serviceId}_${venueId}`);
                } else {
                    areaIds.forEach((areaId) => {
                        if (areaId in parentVenueIdByAreaIds) {
                            const parentVenueId = parentVenueIdByAreaIds[areaId].map(
                                (venueDetails) => venueDetails?.split("_")[0]
                            );
                            if (parentVenueId.indexOf(venueId) >= 0) {
                                result.push(`${serviceId}_${venueId}_${areaId}`);
                            }
                        }
                    });
                }
            });
        }
    });

    return result;
};

const dayMapper = {
    Monday: "1",
    Tuesday: "2",
    Wednesday: "3",
    Thursday: "4",
    Friday: "5",
    Saturday: "6",
    Sunday: "7",
};

// THE OTHER ONE
export const useGetSalesAndStaffHoursByDates = (params: GetSalesAndStaffHours) => {
    const {
        enabled = true,
        range: { start, end },
    } = params;
    const { cubejsApi } = useContext(CubeContext);

    const { selectedAreas, selectedClasses, selectedVenues } = useSegmentsFilter();
    const { isLoading: loadingMappingRules, data: mappingRules } =
        useListMappingRules();
    const { data: integrationSources, isLoading: loadingIntegrationSources } =
        useListIntegrationSources();

    const { selectedHours } = useHoursFilter();
    const { selectedDays } = useDaysFilter();

    const selectedDaysToNum = useMemo(
        () => selectedDays?.map((day) => dayMapper[day]),
        [selectedDays]
    );

    const venues = useMemo(
        () =>
            chain(
                mappingRules?.filter(
                    ({ segment, exclude }) => segment === "venue" && !exclude
                )
            )
                .keyBy("segmentName")
                .mapValues("id")
                .value(),
        [mappingRules]
    );

    const classes = useMemo(
        () =>
            chain(
                mappingRules?.filter(
                    ({ segment, exclude }) => segment === "class" && !exclude
                )
            )
                .keyBy("segmentName")
                .mapValues("id")
                .value(),
        [mappingRules]
    );

    const areas = useMemo(
        () =>
            chain(
                mappingRules?.filter(
                    ({ segment, exclude }) => segment === "area" && !exclude
                )
            )
                .keyBy("segmentName")
                .mapValues("id")
                .value(),
        [mappingRules]
    );

    const { data: segments, isLoading: loadingUnmappedSegments } =
        useListSegmentsByIntegrationSource(
            integrationSources
                ?.map(({ integrationName }) => integrationName)
                .join(",")
        );

    const serviceIdsByIntegrationName = integrationSources
        ? chain(integrationSources)
              .keyBy("integrationName")
              .mapValues("services")
              .value()
        : undefined;

    const venueById = useMemo(
        () =>
            segments
                ? chain(segments)
                      .filter(({ segmentType }) => segmentType === "venue")
                      .keyBy(
                          ({ integrationName, segmentId }) =>
                              `${integrationName}_${segmentId}`
                      )
                      .mapValues(
                          ({ segmentName, segmentId }) =>
                              `${segmentId}_${segmentName}`
                      )
                      .value()
                : undefined,
        [segments]
    );

    const parentVenueIdByAreaId = useMemo(
        () =>
            venueById && segments
                ? chain(segments)
                      .filter(({ segmentType }) => segmentType === "area")
                      .map(
                          ({
                              integrationName,
                              parentSegmentId,
                              segmentId,
                              segmentName,
                          }) => ({
                              key: `${segmentId}_${segmentName.split("_")[0]}`,
                              value: venueById[
                                  `${integrationName}_${parentSegmentId}`
                              ],
                          })
                      )
                      .groupBy("key")
                      .mapValues((groupedItems) =>
                          groupedItems.map((item) => item.value)
                      )
                      .value()
                : undefined,
        [segments, venueById]
    );

    const allMappingRules = mappingRules?.reduce<{
        excludedMappingRules: MappingRule[];
        mappingRules: MappingRule[];
    }>(
        (result, mappingRule) => {
            if (mappingRule.exclude) {
                result.excludedMappingRules.push(mappingRule);
            } else {
                result.mappingRules.push(mappingRule);
            }

            return result;
        },
        {
            excludedMappingRules: [],
            mappingRules: [],
        }
    );

    const mappingRuleBySegment =
        serviceIdsByIntegrationName &&
        allMappingRules?.mappingRules &&
        parentVenueIdByAreaId
            ? allMappingRules?.mappingRules.reduce<KeyValue<string>>(
                  (result, mappingResult) => {
                      if (!mappingResult.result) {
                          return result;
                      }

                      const processedMappingResult =
                          mappingResult.segment === "area"
                              ? addVenueIdsIntoParsingResult({
                                    result: mappingResult.result,
                                    parentVenueIdByAreaId,
                                })
                              : mappingResult.result;

                      const segmentTree = processedMappingResult.flatMap(
                          ({ integrationSource, classIds, venueIds, areaIds }) =>
                              buildSegmentTree({
                                  classIds: classIds?.map((classId) => {
                                      const [id, name] = classId.split("_");
                                      if (id === "null") {
                                          return name;
                                      }

                                      return classId;
                                  }),
                                  venueIds: venueIds
                                      ?.filter(Boolean)
                                      .map((venueId) => venueId.split("_")[0]),
                                  areaIds: areaIds,
                                  serviceIds:
                                      serviceIdsByIntegrationName[integrationSource],
                                  parentVenueIdByAreaIds: parentVenueIdByAreaId,
                              })
                      );

                      segmentTree.forEach((segment) => {
                          result[segment] = mappingResult.id;
                      });
                      return result;
                  },
                  {}
              )
            : undefined;

    const excludeMappingRuleBySegment =
        serviceIdsByIntegrationName &&
        allMappingRules?.excludedMappingRules &&
        parentVenueIdByAreaId
            ? allMappingRules?.excludedMappingRules.reduce<KeyValue<string>>(
                  (result, mappingResult) => {
                      if (!mappingResult.result) {
                          return result;
                      }

                      const processedMappingResult =
                          mappingResult.segment === "area"
                              ? addVenueIdsIntoParsingResult({
                                    result: mappingResult.result,
                                    parentVenueIdByAreaId,
                                })
                              : mappingResult.result;

                      const segmentTree = processedMappingResult.flatMap(
                          ({ integrationSource, classIds, venueIds, areaIds }) =>
                              buildSegmentTree({
                                  classIds: classIds?.map((classId) => {
                                      const [id, name] = classId.split("_");
                                      if (id === "null") {
                                          return name;
                                      }

                                      return classId;
                                  }),
                                  venueIds: venueIds
                                      ?.filter(Boolean)
                                      .map((venueId) => venueId.split("_")[0]),
                                  areaIds: areaIds,
                                  serviceIds:
                                      serviceIdsByIntegrationName[integrationSource],
                                  parentVenueIdByAreaIds: parentVenueIdByAreaId,
                              })
                      );

                      segmentTree.forEach((segment) => {
                          result[segment] = mappingResult.id;
                      });
                      return result;
                  },
                  {}
              )
            : undefined;

    const groupServices = useMemo(
        () =>
            serviceIdsByIntegrationName
                ? Object.values(serviceIdsByIntegrationName).flatMap((value) =>
                      value.map(String)
                  )
                : undefined,
        [serviceIdsByIntegrationName]
    );

    const excludedMappingResults = useMemo(() => {
        return allMappingRules?.excludedMappingRules.flatMap(({ result }) => result);
    }, [allMappingRules?.excludedMappingRules]);

    const serviceFilterForExclusionRule = useMemo(() => {
        if (
            !excludedMappingResults ||
            !serviceIdsByIntegrationName ||
            !groupServices
        )
            return [];

        const serviceIds = chain(excludedMappingResults)
            .groupBy("integrationSource")
            .flatMap(
                (_, integrationSource) =>
                    serviceIdsByIntegrationName[integrationSource]
            )
            .value();
        if (serviceIds.length === groupServices.length) {
            return [];
        }
        return [
            {
                member: `${CubeName.noPreAgg}.serviceId`,
                values: groupServices.filter(
                    (serviceId) => !serviceIds.includes(Number(serviceId))
                ),
                operator: "equals",
            },
        ];
    }, [excludedMappingResults, groupServices, serviceIdsByIntegrationName]);

    const { numberOfWeeksSelected } = useMemo(() => {
        return calculateNumberOfDaysAndWeeksSelectedInRange(start, end);
    }, [end, start]);

    const query =
        enabled && excludedMappingResults && groupServices
            ? CubeQueryBuilder({
                  measures: [
                      `${CubeName.noPreAgg}.transactionTotal`,
                      `${CubeName.noPreAgg}.activeStaffHourly`,
                  ],
                  order: { [`${CubeName.noPreAgg}.period`]: "asc" },
                  dimensions: [
                      "foreignVenue",
                      "foreignArea",
                      "foreignClass",
                      "foreignVenueID",
                      "foreignAreaID",
                      "serviceId",
                  ].map((dimension) => `${CubeName.noPreAgg}.${dimension}`),
                  filters: [
                      {
                          member: `${CubeName.noPreAgg}.serviceId`,
                          values: groupServices,
                          operator: "equals",
                      },
                  ],
              })
                  .addFilters(
                      [
                          {
                              member: `${CubeName.noPreAgg}.period`,
                              values: [start, end],
                              operator: "inDateRange",
                          },
                      ],
                      numberOfWeeksSelected >= 52
                  )
                  .addTimeDimensions(
                      [
                          {
                              dimension: `${CubeName.noPreAgg}.period`,
                              dateRange: [start, end],
                              granularity: "year",
                          },
                      ],
                      numberOfWeeksSelected < 52
                  )
                  .addFilters(
                      [
                          {
                              or: [
                                  ...serviceFilterForExclusionRule,
                                  ...excludedMappingResults.map((result) => {
                                      return {
                                          and: [
                                              {
                                                  member: `${CubeName.noPreAgg}.serviceId`,
                                                  values: groupServices,
                                                  operator: "equals",
                                              },
                                              ...buildCubeFilters({
                                                  cubeName: CubeName.noPreAgg,
                                                  mappingResults: [result],
                                                  operator: "notEquals",
                                                  serviceIdsByIntegrationName,
                                              }),
                                          ],
                                      };
                                  }),
                              ],
                          },
                      ],
                      excludedMappingResults.length > 0
                  )
                  .addFilters(
                      [
                          {
                              member: `${CubeName.noPreAgg}.hour`,
                              operator: "equals",
                              values: selectedHours?.map(String),
                          },
                      ],
                      selectedHours != undefined &&
                          selectedHours.length !== 0 &&
                          selectedHours.length !== 24
                  )
                  .addFilters(
                      [
                          {
                              member: `${CubeName.noPreAgg}.shiftDay`,
                              operator: "equals",
                              values: selectedDaysToNum,
                          },
                      ],
                      selectedDaysToNum !== undefined &&
                          selectedDaysToNum?.length !== 0
                  )
                  .getResult()
            : null;

    const {
        isLoading: fetchingSalesAndStaffHours,
        data: saleAndStaffHoursBySegment,
    } = useQuery(
        [QueryKey.salesAndStaffHours, query],
        () => cubejsApi.load(query!),
        {
            enabled: Boolean(query),
            select: (result) => {
                return result.rawData().map((d) => ({
                    activeStaff: d[`${CubeName.noPreAgg}.activeStaffHourly`],
                    areaName: d[`${CubeName.noPreAgg}.foreignArea`],
                    areaId: d[`${CubeName.noPreAgg}.foreignAreaID`],
                    className: d[`${CubeName.noPreAgg}.foreignClass`],
                    venueName: d[`${CubeName.noPreAgg}.foreignVenue`],
                    venueId: d[`${CubeName.noPreAgg}.foreignVenueID`],
                    serviceId: d[`${CubeName.noPreAgg}.serviceId`],
                    transactionTotal: d[`${CubeName.noPreAgg}.transactionTotal`],
                })) as SaleAndStaffHoursBySegment[];
            },
        }
    );

    const totalSalesAndStaffHoursByMappingRule = useMemo(() => {
        if (!saleAndStaffHoursBySegment || !mappingRuleBySegment) {
            return undefined;
        }
        const result = saleAndStaffHoursBySegment.reduce<
            Record<string, TotalSalesAndStaffHours>
        >(
            (
                result,
                {
                    serviceId,
                    venueId,
                    areaId,
                    className,
                    transactionTotal,
                    activeStaff,
                    areaName,
                }
            ) => {
                const venueMapped = mappingRuleBySegment[`${serviceId}_${venueId}`];
                const isExcludeVenueMapped = excludeMappingRuleBySegment
                    ? excludeMappingRuleBySegment[`${serviceId}_${venueId}`]
                    : false;
                const areaMapped =
                    mappingRuleBySegment[
                        `${serviceId}_${venueId}_${areaId}_${areaName}`
                    ];

                const isExcludeAreaMapped = excludeMappingRuleBySegment
                    ? excludeMappingRuleBySegment[
                          `${serviceId}_${venueId}_${areaId}_${areaName}`
                      ]
                    : false;

                const classMapped =
                    mappingRuleBySegment[`${serviceId}_${areaId}_${className}`] ??
                    mappingRuleBySegment[`${serviceId}_${className}`];

                const isExcludeClassMapped = excludeMappingRuleBySegment
                    ? excludeMappingRuleBySegment[
                          `${serviceId}_${areaId}_${className}`
                      ] ?? excludeMappingRuleBySegment[`${serviceId}_${className}`]
                    : false;

                const includeVenue =
                    !selectedVenues ||
                    selectedVenues.length === 0 ||
                    selectedVenues.includes(venueMapped);

                const includeArea =
                    !selectedAreas ||
                    selectedAreas.length === 0 ||
                    selectedAreas.includes(areaMapped);

                const includeClass =
                    !selectedClasses ||
                    selectedClasses.length === 0 ||
                    selectedClasses.includes(classMapped);

                const sale = formatNumberToFixed(transactionTotal);
                const staffHours = formatNumberToFixed(activeStaff);

                const isExcludeTrue =
                    isExcludeVenueMapped ||
                    isExcludeAreaMapped ||
                    isExcludeClassMapped;

                if (!isExcludeTrue) {
                    if (venueMapped) {
                        if (includeArea && includeClass) {
                            result[venueMapped] = result[venueMapped]
                                ? {
                                      totalSales: formatNumberToFixed(
                                          sale + result[venueMapped].totalSales
                                      ),
                                      totalStaffHours: formatNumberToFixed(
                                          staffHours +
                                              result[venueMapped].totalStaffHours
                                      ),
                                  }
                                : { totalSales: sale, totalStaffHours: staffHours };
                        }
                    } else {
                        result["unmappedVenue"] = result["unmappedVenue"]
                            ? {
                                  totalSales: formatNumberToFixed(
                                      sale + result["unmappedVenue"].totalSales
                                  ),
                                  totalStaffHours: formatNumberToFixed(
                                      staffHours +
                                          result["unmappedVenue"].totalStaffHours
                                  ),
                              }
                            : { totalSales: sale, totalStaffHours: staffHours };
                    }

                    if (areaMapped) {
                        if (includeVenue && includeClass) {
                            result[areaMapped] = result[areaMapped]
                                ? {
                                      totalSales: formatNumberToFixed(
                                          sale + result[areaMapped].totalSales
                                      ),
                                      totalStaffHours: formatNumberToFixed(
                                          staffHours +
                                              result[areaMapped].totalStaffHours
                                      ),
                                  }
                                : { totalSales: sale, totalStaffHours: staffHours };
                        }
                    } else {
                        if (includeVenue && includeClass) {
                            result["unmappedArea"] = result["unmappedArea"]
                                ? {
                                      totalSales: formatNumberToFixed(
                                          sale + result["unmappedArea"].totalSales
                                      ),
                                      totalStaffHours: formatNumberToFixed(
                                          staffHours +
                                              result["unmappedArea"].totalStaffHours
                                      ),
                                  }
                                : { totalSales: sale, totalStaffHours: staffHours };
                        }
                    }

                    if (classMapped) {
                        if (includeArea && includeVenue) {
                            result[classMapped] = result[classMapped]
                                ? {
                                      totalSales: formatNumberToFixed(
                                          sale + result[classMapped].totalSales
                                      ),
                                      totalStaffHours: formatNumberToFixed(
                                          staffHours +
                                              result[classMapped].totalStaffHours
                                      ),
                                  }
                                : { totalSales: sale, totalStaffHours: staffHours };
                        }
                    } else {
                        if (includeVenue && includeArea) {
                            result["unmappedClass"] = result["unmappedClass"]
                                ? {
                                      totalSales: formatNumberToFixed(
                                          sale + result["unmappedClass"].totalSales
                                      ),
                                      totalStaffHours: formatNumberToFixed(
                                          staffHours +
                                              result["unmappedClass"].totalStaffHours
                                      ),
                                  }
                                : { totalSales: sale, totalStaffHours: staffHours };
                        }
                    }
                    result["totals"] = result["totals"]
                        ? {
                              totalSales: formatNumberToFixed(
                                  sale + result["totals"].totalSales
                              ),
                              totalStaffHours: formatNumberToFixed(
                                  staffHours + result["totals"].totalStaffHours
                              ),
                          }
                        : { totalSales: sale, totalStaffHours: staffHours };
                }

                return result;
            },
            {}
        );

        return result;
    }, [
        mappingRuleBySegment,
        saleAndStaffHoursBySegment,
        selectedAreas,
        selectedClasses,
        selectedVenues,
    ]);

    const totalSalesAndStaffHours = useMemo(() => {
        if (!totalSalesAndStaffHoursByMappingRule) {
            return undefined;
        }

        const result = getDefaultTotalSalesAndStaffHours();
        if (
            (!selectedAreas || selectedAreas.length === 0) &&
            (!selectedClasses || selectedClasses.length === 0) &&
            (!selectedVenues || selectedVenues.length === 0)
        ) {
            return {
                totalSales:
                    totalSalesAndStaffHoursByMappingRule?.["totals"]?.totalSales ||
                    result.totalSales,
                totalStaffHours:
                    totalSalesAndStaffHoursByMappingRule?.["totals"]
                        ?.totalStaffHours || result.totalStaffHours,
            };
        }

        if (selectedVenues && selectedVenues.length > 0) {
            selectedVenues.forEach((selectedVenue) => {
                const currentVenue = totalSalesAndStaffHoursByMappingRule?.[
                    selectedVenue
                ]
                    ? totalSalesAndStaffHoursByMappingRule?.[selectedVenue]
                    : totalSalesAndStaffHoursByMappingRule?.[venues[selectedVenue]];

                result.totalSales += currentVenue?.totalSales ?? 0;
                result.totalStaffHours += currentVenue?.totalStaffHours ?? 0;
            });
        } else if (selectedAreas && selectedAreas.length > 0) {
            selectedAreas.forEach((selectedArea) => {
                const currentArea = totalSalesAndStaffHoursByMappingRule?.[
                    selectedArea
                ]
                    ? totalSalesAndStaffHoursByMappingRule?.[selectedArea]
                    : totalSalesAndStaffHoursByMappingRule?.[areas[selectedArea]];
                result.totalSales += currentArea?.totalSales ?? 0;
                result.totalStaffHours += currentArea?.totalStaffHours ?? 0;
            });
        } else if (selectedClasses && selectedClasses.length > 0) {
            selectedClasses.forEach((selectedClass) => {
                const currentClass = totalSalesAndStaffHoursByMappingRule?.[
                    selectedClass
                ]
                    ? totalSalesAndStaffHoursByMappingRule?.[selectedClass]
                    : totalSalesAndStaffHoursByMappingRule?.[classes[selectedClass]];
                result.totalSales += currentClass?.totalSales ?? 0;
                result.totalStaffHours += currentClass?.totalStaffHours ?? 0;
            });
        }

        return {
            totalSales: formatNumberToFixed(result.totalSales),
            totalStaffHours: formatNumberToFixed(result.totalStaffHours),
        };
    }, [
        saleAndStaffHoursBySegment,
        selectedAreas,
        selectedClasses,
        selectedVenues,
        totalSalesAndStaffHoursByMappingRule,
    ]);

    const isLoading =
        loadingMappingRules ||
        loadingIntegrationSources ||
        loadingUnmappedSegments ||
        fetchingSalesAndStaffHours;

    return {
        isLoading,
        query,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursByMappingRule,
    };
};
