import List from "antd-mobile/es/components/list";
import { Form, Select } from "antd";
import CascadePicker, {
    CascadePickerOption,
} from "antd-mobile/es/components/cascade-picker";

import React, { ReactElement, useEffect, useState } from "react";
import { View } from "./index";
import styled from "styled-components";
interface Props {
    className?: string;
    views: View[];
    onSelectView;
    selectedViewId: number | undefined;
    defaultViewId: number | null;
}

const StyledCascadePicker = styled(CascadePicker)`
    .adm-picker-header {
        user-select: contain !important;
    }
`;

const StyledSelect = styled(Select)`
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 16px;
    }
`;

const StyledNoSelectedDivTag = styled.div`
    user-select: none;
`;

const StyledListItem = styled(List.Item)`
    .adm-list-item-content-extra {
        user-select: none !important;
    }
    .adm-list-item-content-main {
        user-select: none !important;
    }
`;

export default function ViewSelectMobile({
    className,
    views,
    onSelectView,
    selectedViewId,
    defaultViewId,
}: Props): ReactElement {
    const [pickerData, setPickerData] = useState<CascadePickerOption[]>([]);
    const [options, setOptions] = useState<ReactElement[]>([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setPickerData(
            views.map((view) => ({
                value: view.view_id.toString(),
                label:
                    view.view_id === defaultViewId
                        ? `${view.view_label} (default)`
                        : view.view_label,
            }))
        );
    }, [views]);

    useEffect(() => {
        const children: ReactElement[] = [];
        views.forEach((view) => {
            children.push(
                <Select.Option key={view.view_id} value={view.view_id}>
                    {view.view_label} {defaultViewId === view.view_id && "(default)"}
                </Select.Option>
            );
        });
        setOptions(children);
    }, [views]);

    // const onChange = (value) => {
    //     const viewId = value[0];
    //     const view: View = views.find((view) => view.view_id === parseInt(viewId))!;
    //     if (view === undefined) {
    //         //apply no filters if view not detected
    //         setIsVisible(false);
    //         return;
    //     }
    //     onSelectView(view);
    //     setIsVisible(false);
    // };

    const onChange = (viewId) => {
        const view: View = views.find((view) => view.view_id === parseInt(viewId))!;
        if (view === undefined) {
            return;
        }
        onSelectView(view);
    };

    return (
        <>
            {/* <List style={{ width: "100%" }}>
                <StyledListItem
                    onClick={() => setIsVisible(true)}
                    arrow
                    extra="Select"
                >
                    View Select
                </StyledListItem>
            </List> */}
            {/* <StyledCascadePicker
                // confirmText={
                //     <StyledNoSelectedDivTag>{"Confirm"}</StyledNoSelectedDivTag>
                // }
                // cancelText={
                //     <StyledNoSelectedDivTag>{"Cancel"}</StyledNoSelectedDivTag>
                // }
                // className={className}
                // onConfirm={(val, extend) => onChange(val)}
                // onCancel={() => setIsVisible(false)}
                options={pickerData}
                // visible={isVisible}
            /> */}
            <div style={{ fontSize: "18px" }}>Views</div>
            <StyledSelect
                style={{ width: "100%" }}
                placeholder={"View Select"}
                onChange={onChange}
                className={className}
                value={
                    views.find((option) => option.view_id === selectedViewId)
                        ?.view_id
                }
                dropdownMatchSelectWidth={true}
            >
                {options}
            </StyledSelect>
        </>
    );
}
