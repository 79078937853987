import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Context } from "../../State/store";
import { getGroupData, GroupData } from "../../Api/backend";
import jwtDecode from "jwt-decode";
import LandingPage from "./LandingPage";
import ForecastCreation from "./ForecastCreation";
import { useParams } from "react-router-dom";
import { Button, Col, Divider, Modal, Row, Skeleton, Spin, Typography } from "antd";
import { RoundedCard } from "Components/HomeDashboardDesktop";
import CustomLoader from "Components/CustomLoader";
import useCurrentForecast from "Hooks/useCurrentForecast";
import styled from "styled-components";

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 8px;
    }
`;
const StyledModalFooter = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;

const StyledSaveButton = styled(Button)`
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;

    &,
    &:hover,
    &:active,
    &:focus {
        background: #1a81c7;
        border-color: #1a81c7;
        color: white;
    }
`;
export interface ForecastPageParams {
    id: string;
}

export default function ForecastingDashboardDesktop(): ReactElement {
    const [loading, setLoading] = useState<boolean>(true);
    const [{ groupData }, dispatch] = useContext(Context);
    const [visible, setVisible] = useState(true);

    const { id } = useParams<ForecastPageParams>();
    const { forecast } = useCurrentForecast();

    useEffect(() => {
        if (groupData == undefined) {
            setLoading(true);
            getGroupData().then((groupData: GroupData) => {
                const datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                    .dataset_name;
                const addGroupDataPayload = {
                    groupData,
                };

                dispatch({ type: "ADD_GROUP_DATA", payload: addGroupDataPayload });
                dispatch({
                    type: "ADD_DATASET_NAME_TO_FILTER",
                    payload: { datasetName },
                });

                setLoading(false);
            });
        } else {
            const datasetName = (jwtDecode(groupData!.cubejsToken) as any).user
                .dataset_name;
            dispatch({
                type: "ADD_DATASET_NAME_TO_FILTER",
                payload: { datasetName },
            });
            setLoading(false);
        }
    }, [dispatch, id, groupData]);

    if (loading) {
        return (
            <Spin indicator={<CustomLoader />}>
                <Row gutter={[8, 20]}>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    if (id && !forecast)
        return (
            <>
                <StyledModal
                    visible={visible}
                    destroyOnClose
                    style={{ width: "fit-content" }}
                    footer={null}
                >
                    <Typography.Title
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                    >
                        Forecast not exist
                    </Typography.Title>
                    <Divider />
                    <p> The forecast id in the link does not exist, please check</p>
                    <Divider />
                    <StyledModalFooter>
                        <StyledSaveButton onClick={() => setVisible(false)}>
                            OK
                        </StyledSaveButton>
                    </StyledModalFooter>
                </StyledModal>
                <LandingPage />
            </>
        );
    if (id) {
        return <ForecastCreation />;
    }

    return <LandingPage />;
}
