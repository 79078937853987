import React, { useCallback, useEffect, useState } from "react";
import { Nav } from "quantaco-menu";
import auth0 from "auth0-js";
import { useMsal } from "@azure/msal-react";
import { config } from "../../Api/auth/config";
import "quantaco-menu/style.css";
import { Notification } from "../../@types";
import { getNotifications, postNotification } from "../../Api/backend";

const auth0Instance = new auth0.WebAuth({
    domain: config.auth0Domain!,
    clientID: config.auth0ClientId!,
    audience: config.auth0Audience,
    scope: config.auth0Scope,
    responseType: "token id_token",
});

const NewNavBar = () => {
    const { instance, accounts } = useMsal();
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;
    const quantacoHomeUrl = process.env.REACT_APP_QUANTACO_PLATFORM_URL;
    const [notifications, setNotifications] = useState([] as Notification[]);

    //get notifications list
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response: any = await getNotifications();
                setNotifications(response);
            } catch (error: any) {
                console.log("error");
            }
        };
        fetchNotifications();

        // fetch notifications every 5 minutes
        const intervalId = setInterval(fetchNotifications, 300000);
        return () => clearInterval(intervalId);
    }, []);

    const handleNotificationClick = useCallback(
        async (notification: Notification) => {
            try {
                await postNotification(notification.notification_id);
            } catch (errorResponse) {
                console.log("Notification update error: ", errorResponse);
            }
        },
        []
    );

    return (
        <Nav
            //@ts-ignore
            notifications={notifications}
            onNotificationClick={handleNotificationClick}
            auth0Instance={auth0Instance}
            auth0_client_id={auth0ClientId ? auth0ClientId : ""}
            ms_instance={instance}
            accounts={accounts}
            homeUrl={quantacoHomeUrl ? quantacoHomeUrl : "/"}
        />
    );
};
export default NewNavBar;
