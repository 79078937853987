import auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import { refreshAuthToken } from "./auth0API";
import { config } from "./config";
import { AuthTokens, Auth0Result } from "./types";

export type AuthState = "authenticated" | "unauthenticated" | "expired";

export class Auth {
    private static instance: Auth | null = null;

    auth0 = new auth0.WebAuth({
        domain: config.auth0Domain!,
        clientID: config.auth0ClientId!,
        audience: config.auth0Audience,
        scope: config.auth0Scope,
        responseType: "token id_token",
    });

    silentauth0 = new auth0.WebAuth({
        domain: config.auth0Domain!,
        clientID: config.auth0ClientId!,
        audience: config.auth0Audience,
        scope: config.auth0Scope,
        responseType: "token id_token",
        redirectUri: window.location.origin + window.location.pathname,
    });

    authState: AuthState = "unauthenticated";
    listeners: ((authState: AuthState) => void)[] = [];
    constructor() {
        this.signin = this.signin.bind(this);
        this.silentAuth = this.silentAuth.bind(this);
        this.setSession = this.setSession.bind(this);
        this.isIdPInitiatedSSO = this.isIdPInitiatedSSO.bind(this);
        if (isAuthenticated()) {
            this.authState = "authenticated";
        } else {
            const expiresAt = JSON.parse(localStorage.getItem("expires_at")!);
            if (new Date().getTime() > expiresAt) {
                this.authState = "expired";
            }
        }
    }

    public static getInstance(): Auth {
        if (!Auth.instance) {
            Auth.instance = new Auth();
        }
        return Auth.instance;
    }
    silentAuth(): Promise<Auth0Result | Error> {
        return new Promise((resolve, reject) => {
            this.silentauth0.checkSession({}, (err, authResult) => {
                if (err) {
                    console.log("Silent authentication failed:", err);
                    return reject(err);
                }
                console.log("Silent authentication successful:", authResult);
                setSession(authResult);
                this.setAuthState("authenticated");
                return resolve(authResult);
            });
        });
    }

    isIdPInitiatedSSO(): boolean {
        const hash = window.location.hash;
        return hash.includes("access_token") || hash.includes("id_token");
    }

    interactiveAuth = () => {
        this.auth0.authorize();
    };

    signin(username: string, password: string): Promise<Auth0Result | Error> {
        return new Promise((resolve, reject) => {
            this.auth0.client.login(
                { realm: config.auth0Realm!, username, password },
                (err, authResult) => {
                    if (err) {
                        console.log(err);
                        return reject(err);
                    }
                    this.setSession(authResult.accessToken, authResult.refreshToken);
                    this.setAuthState("authenticated");
                    return resolve(authResult);
                }
            );
        });
    }
    async setSession(accessToken: string, refreshToken?: string) {
        setSession({ accessToken, refreshToken });
        this.setAuthState("authenticated");
        await refreshAuthToken();
    }

    setAuthState(state: AuthState) {
        this.authState = state;
        this.notify();
    }

    checkSession(): void {
        // check if the session is expired
        const expiresAt = JSON.parse(localStorage.getItem("expires_at")!);
        if (new Date().getTime() < expiresAt) {
            this.setAuthState("authenticated");
        } else {
            this.setAuthState("expired");
        }
    }

    getAuthState(): AuthState {
        return this.authState;
    }

    subscribe(fn: (authState: AuthState) => void) {
        this.listeners.push(fn);
    }

    unsubscribe(fn: (authState: AuthState) => void) {
        this.listeners = this.listeners.filter((subscriber) => subscriber !== fn);
    }

    notify() {
        this.listeners.forEach((subscriber) => subscriber(this.authState));
    }
}
export const getUserToken = (idType: AuthTokens): string | null => {
    return localStorage.getItem(idType);
};

/**
 * If the expiry time is not explicitly set we assume the default case (24hr expiry)
 *
 */
const setExpiryTime = () => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 23);
    return currentDate.getTime();
};

export const setSession = (oldAuthResult: Auth0Result): void => {
    const authResult = { ...oldAuthResult };

    if (authResult.id_token) {
        authResult["idToken"] = authResult.id_token;
    }

    if (authResult.access_token) {
        authResult["accessToken"] = authResult.access_token;
    }

    try {
        jwtDecode(authResult.accessToken!);
    } catch (e) {
        window.dispatchEvent(new Event("token-expire"));
    }

    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn
        ? JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
        : setExpiryTime();

    if (authResult.accessToken) {
        localStorage.setItem(AuthTokens.accessToken, authResult.accessToken);
    } else {
        throw new Error("accessToken missing");
    }

    if (authResult.idToken) {
        localStorage.setItem(AuthTokens.idToken, authResult.idToken);
    }

    localStorage.setItem(AuthTokens.expiresIn, expiresAt.toString());

    if (authResult.idToken) {
        localStorage.setItem(
            AuthTokens.email,
            (jwtDecode(authResult.idToken!) as any).email
        );
        localStorage.setItem(
            AuthTokens.userId,
            (jwtDecode(authResult.idToken!) as any).sub
        );
    }

    if (authResult.refreshToken) {
        localStorage.setItem(AuthTokens.refreshToken, authResult.refreshToken!);
    }
};

export const isAuthenticated = (): boolean => {
    // Check whether the current time is past the access token's expiry time
    const expiresAt = parseInt(localStorage.getItem(AuthTokens.expiresIn)!);
    return new Date().getTime() < expiresAt;
};
