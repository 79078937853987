import React, { ReactElement, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { RoutePaths } from "./Routes";

// Pages/components
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import AuthCheckRoute from "./AuthedRoute";
import { MappingConfiguratorEditorPage } from "Pages/MappingConfigurator/MappingConfiguratorEditorPage";
import Forecasting from "Pages/Forecasting";
import { ForecastPage } from "Pages/Forecast/ForecastPage";
import { MappingConfiguratorPage } from "Pages/MappingConfigurator/MappingConfiguratorPage";
import HomePage from "Pages/HomePage";
import LoginPage from "../../Pages/LoginPage";
const PageNotFound = React.lazy(() => import("../../Pages/PageNotFound"));

// Lazy loaded Pages/components (see https://reactjs.org/docs/code-splitting.html for docs/info)
const UsersPage = React.lazy(() => import("../../Pages/UserManagement/Users"));
const SomethingWentWrongPage = React.lazy(
    () => import("../../Pages/SomethingWentWrongPage")
);
const Profile = React.lazy(() => import("../../Pages/UserManagement/Profile"));

export default function Router(): ReactElement {
    return (
        <Suspense fallback={<QuantacoLoader size={135} />}>
            <Switch>
                <AuthCheckRoute exact path={RoutePaths.HOME} component={HomePage} />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.FORECASTING_LANDING}
                    component={Forecasting}
                />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.FORECAST}
                    component={ForecastPage}
                />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.FORECASTING_CREATION}
                    component={Forecasting}
                />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.MAPPING}
                    component={MappingConfiguratorPage}
                />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.MAPPING_EDITION}
                    component={MappingConfiguratorEditorPage}
                />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.USERS}
                    component={UsersPage}
                />
                <AuthCheckRoute
                    exact
                    path={RoutePaths.PROFILE}
                    component={Profile}
                />

                <Route path={RoutePaths.LOGIN} component={LoginPage} />
                {/* <Route
                        exact
                        path={RoutePaths.SET_PASSWORD}
                        component={SetPassword}
                    /> */}

                <Route
                    path={RoutePaths.SOMETHING_WENT_WRONG}
                    component={SomethingWentWrongPage}
                />
                <Route path={RoutePaths.PAGE_NOT_FOUND} component={PageNotFound} />

                <Route path="*" component={PageNotFound} />
            </Switch>
        </Suspense>
    );
}
