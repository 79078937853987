import React, { ReactElement, useMemo } from "react";
import { Skeleton, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import { ApexChartsSeries } from "../../Api/Cube/types";
import {
    QUANTACO_CHART_DARK_BLUE,
    QUANTACO_DARK_ORANGE,
} from "../../Utils/constants";
import { FilteredSegments } from "./index";
import {
    DEFAULT_GRANULARITY_IN_MINUTES,
    MINUTES_IN_HOURS,
} from "../../Utils/date-utils";
import { currencyFormatter } from "Utils/utils";
import "./BaselineChartForAutogen.css";

export interface BaselineRawRow {
    mapped_area: string;
    mapped_class: string;
    relative_timestamp: string;
    base: number;
    forecast?: number;
}

interface BasicMappedRow {
    x: string;
}

export interface BaselineMappedRow extends BasicMappedRow {
    [base: string]: number | string;
}

interface BaselineSeries {
    series: ApexChartsSeries;
}
export const groupDataByField =
    (resultArr: BaselineMappedRow[], targetField: string): any =>
    (res: {}, value: BaselineRawRow) => {
        const { base, forecast } = value;
        const targetMapping = value[targetField];

        if (!res[targetMapping]) {
            res[targetMapping] = {
                x: targetMapping,
                base: 0,
                forecast: 0,
            };
            resultArr.push(res[targetMapping]);
        }
        res[targetMapping].base += base;
        if (forecast) {
            res[targetMapping].forecast += forecast;
        }
        return res;
    };

interface ChartOptions {
    options?: ApexOptions;
    brushChartOptions?: ApexOptions;
}

interface Props {
    baselineData: BaselineRawRow[];
    forecastData: BaselineRawRow[];
    loading: boolean;
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen";
    filteredSegments: FilteredSegments;
}

const BaselineChartForAutogen = ({
    baselineData,
    forecastData,
    loading,
    mode,
    filteredSegments,
}: Props): ReactElement => {
    const baseName: string = useMemo(() => {
        return mode === "baseline" || mode === "drivers"
            ? "Baseline"
            : mode === "autogen" || mode === "drivers_autogen"
            ? "Autogen"
            : "";
    }, [mode]);

    const forecastName: string = useMemo(() => {
        return mode === "baseline"
            ? "Autogen"
            : mode === "drivers"
            ? "Baseline Drivers"
            : mode === "autogen"
            ? "Baseline"
            : mode === "drivers_autogen"
            ? "Autogen Drivers"
            : "";
    }, [mode]);

    const result = useMemo(() => {
        if (!loading && baselineData.length) {
            const result: BaselineMappedRow[] = [];
            baselineData.reduce(groupDataByField(result, "relative_timestamp"), {});
            return result;
        } else {
            return null;
        }
    }, [loading, baselineData]);

    const removedDuplicatedMondayResult = useMemo(() => {
        if (result !== null)
            return filteredSegments.days.includes("Monday")
                ? result.slice(
                      0,
                      -((MINUTES_IN_HOURS * 6) / DEFAULT_GRANULARITY_IN_MINUTES)
                  )
                : result;
        else {
            return null;
        }
    }, [result, filteredSegments]);

    const resultForecast = useMemo(() => {
        if (!loading && forecastData.length) {
            const result: BaselineMappedRow[] = [];
            forecastData.reduce(groupDataByField(result, "relative_timestamp"), {});
            return result;
        } else {
            return null;
        }
    }, [loading, forecastData]);

    const removedDuplicatedMondayResultForecast = useMemo(() => {
        if (resultForecast !== null)
            return filteredSegments.days.includes("Monday")
                ? resultForecast.slice(
                      0,
                      -((MINUTES_IN_HOURS * 6) / DEFAULT_GRANULARITY_IN_MINUTES)
                  )
                : resultForecast;
        else {
            return null;
        }
    }, [resultForecast, filteredSegments]);

    const series: {
        series: Array<{ name: string; type: string; data: Array<number> }>;
    } | null = useMemo(() => {
        if (
            removedDuplicatedMondayResult !== null ||
            removedDuplicatedMondayResultForecast !== null
        ) {
            const sTemp =
                removedDuplicatedMondayResult !== null
                    ? {
                          series: [
                              {
                                  name: baseName,
                                  type: "line",
                                  data: removedDuplicatedMondayResult.map(
                                      (r) => r.base
                                  ) as number[],
                              },
                          ],
                      }
                    : {
                          series: [],
                      };
            if (
                (mode === "drivers" || mode === "drivers_autogen") &&
                removedDuplicatedMondayResult !== null
            ) {
                sTemp.series.push({
                    name: forecastName,
                    type: "line",
                    data: removedDuplicatedMondayResult.map(
                        (r) => r.forecast
                    ) as number[],
                });
            }
            if (
                (mode === "baseline" || mode === "autogen") &&
                removedDuplicatedMondayResultForecast !== null
            ) {
                sTemp.series.push({
                    name: forecastName,
                    type: "line",
                    data: removedDuplicatedMondayResultForecast.map(
                        (r) => r.base
                    ) as number[],
                });
            }
            return sTemp;
        } else {
            return null;
        }
    }, [
        removedDuplicatedMondayResult,
        removedDuplicatedMondayResultForecast,
        baseName,
        forecastName,
        mode,
    ]);
    const removeDuplicate = useMemo(
        () => removedDuplicatedMondayResult || removedDuplicatedMondayResultForecast,
        [removedDuplicatedMondayResultForecast, removedDuplicatedMondayResult]
    );

    const xAxisData = useMemo(
        () =>
            removeDuplicate && removeDuplicate.length > 0
                ? removeDuplicate.map((row) => row.x)
                : null,
        [removeDuplicate]
    );

    const options = useMemo<ChartOptions | null>(
        () =>
            xAxisData !== null
                ? {
                      options: {
                          chart: {
                              type: "line",
                              id: "baselineChart",
                              toolbar: {
                                  show: false,
                              },
                          },
                          dataLabels: {
                              enabled: false,
                          },
                          tooltip: {
                              enabled: true,
                              x: {
                                  formatter: (val: any, { dataPointIndex }: any) => {
                                      return moment(
                                          xAxisData[dataPointIndex]
                                      ).format("ddd HH:mm (D/M)");
                                  },
                              },
                          },
                          xaxis: {
                              title: {
                                  text: "Day & Time",
                                  style: {
                                      fontSize: "16px",
                                      fontWeight: "600 !important",
                                      color: "#00255d",
                                  },
                                  offsetX: -20,
                              },
                              type: "datetime",
                              categories: xAxisData,
                              tickAmount: 7,
                              stepSize: 1000 * 60 * 60 * 60 * 24,
                              labels: {
                                  rotate: 0,
                                  rotateAlways: false,
                                  formatter: (val) =>
                                      moment(val).format("ddd HH:mm (D/M)"),
                              },
                              crosshairs: {
                                  show: true,
                              },
                              axisTicks: {
                                  show: true,
                              },
                          },
                          colors:
                              mode === "drivers" || mode === "drivers_autogen"
                                  ? [QUANTACO_CHART_DARK_BLUE, QUANTACO_DARK_ORANGE]
                                  : [QUANTACO_DARK_ORANGE, QUANTACO_CHART_DARK_BLUE],

                          yaxis: {
                              title: {
                                  text: "Amount $",
                                  style: {
                                      fontSize: "16px",
                                      fontWeight: "600 !important",
                                      color: "#00255d",
                                  },
                              },
                              tickAmount: 2,
                              labels: {
                                  formatter: (num) => {
                                      return currencyFormatter(num);
                                  },
                              },
                              forceNiceScale: true,
                              showForNullSeries: true,
                          },
                          grid: {
                              show: true,
                          },
                          stroke: {
                              width: [3, 3],
                              dashArray:
                                  mode === "drivers" || mode === "drivers_autogen"
                                      ? [4, 0]
                                      : [0, 4],
                              curve: ["smooth", "smooth"],
                          },
                          fill: {
                              opacity: [1, 0.7],
                              type: "solid",
                              gradient: {
                                  opacityFrom: 1,
                                  opacityTo: 1,
                              },
                          },
                      },
                  }
                : null,
        [xAxisData, mode]
    );

    if (loading) {
        return (
            <>
                <Skeleton active={true} />
                <Skeleton active={true} />
            </>
        );
    } else
        return (
            <>
                {options !== null && series !== null ? (
                    <>
                        <ReactApexChart
                            options={options!.options ? options!.options : {}}
                            series={series!.series ? series!.series : []}
                            width="100%"
                            height={350}
                        />
                    </>
                ) : (
                    <>
                        <Typography.Text type={"secondary"}>
                            {`Missing ${baseName.toLowerCase()} forecast data`}
                        </Typography.Text>
                    </>
                )}
            </>
        );
};

export default BaselineChartForAutogen;
